import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './rootreducer';

// Middleware setup including thunk


// Configure Redux Persist
const persistConfig = {
  key: 'root',
  storage // Specify the storage engine
};

// Wrap rootReducer with persistReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the Redux store with persistedReducer and middleware
const store = configureStore({
  reducer: persistedReducer,
});

// Create persistor object
const persistor = persistStore(store);

export { store, persistor };
