import React from "react";

const School = () => {
  return (
    <div className="our-course-categories-two ">
      <div className="container">
        <div className="categories_wrap">
          <ul className="row unorderList">
            <li className="col-lg-3 col-md-6">
              <div className="categories-course">
                <div className="item-inner">
                  <div className="cours-icon">
                    {" "}
                    <span className="coure-icon-inner">
                      {" "}
                      <img src="assets/images/teacher.png" alt=""  style={{ objectFit: "cover" }}/>{" "}
                    </span>{" "}
                  </div>
                  <div className="cours-title">
                    <h4>Expert teachers</h4>
                    <p>
                      A teacher has professional knowledge and skills gained
                      through formal preparation.
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li className="col-lg-3 col-md-6">
              <div className="categories-course">
                <div className="item-inner">
                  <div className="cours-icon">
                    {" "}
                    <span className="coure-icon-inner">
                      {" "}
                      <img src="assets/images/book.png" alt=""  style={{ objectFit: "cover" }}/>{" "}
                    </span>{" "}
                  </div>
                  <div className="cours-title">
                    <h4>Quality Education</h4>
                    <p>
                      Quality education definition includes: Safe, healthy environment for students.
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li className="col-lg-3 col-md-6">
              <div className="categories-course">
                <div className="item-inner">
                  <div className="cours-icon">
                    {" "}
                    <span className="coure-icon-inner">
                      {" "}
                      <img src="assets/images/support.png" alt=""  style={{ objectFit: "cover" }}/>{" "}
                    </span>{" "}
                  </div>
                  <div className="cours-title">
                    <h4>Life Time Support</h4>
                    <p>
                      Support mechanisms are crucial in helping you grow and
                      scale any type of business.
                    </p>
                  </div>
                </div>
              </div>
            </li>
            <li className="col-lg-3 col-md-6">
              <div className="categories-course">
                <div className="item-inner">
                  <div className="cours-icon">
                    {" "}
                    <span className="coure-icon-inner">
                      {" "}
                      <img src="assets/images/scholarship.png" alt=""  style={{ objectFit: "cover" }}/>{" "}
                    </span>{" "}
                  </div>
                  <div className="cours-title">
                    <h4>Scholarship News</h4>
                    <p>
                      Originality is the essence of true scholarship. Creativity
                      is the soul of the true scholar.
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default School;
